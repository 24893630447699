<template>
    <v-row class="px-3 flex-column flex-sm-row justify-sm-space-between align-items-start mt-1 mb-5">
        <router-link to="/module-inscription" class="text-decoration-none">
            <v-btn color="primary" class="font-weight-bold d-none d-sm-block mb-3">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
            <v-btn color="primary" class="font-weight-bold d-sm-none w-100 mb-3">
                A<span class="text-lowercase">jouter une formation</span>
                <v-icon icon="mdi-plus" class="ml-1"></v-icon>
            </v-btn>
        </router-link>

        <div class="d-flex flex-column align-items-end">
            <router-link @click="handleSubmitLead('/confirmation-inscription')" to="" class="text-decoration-none mb-2">
                <v-btn color="secondary" class="font-weight-bold d-none d-sm-block">
                    C<span class="text-lowercase">onfirmer cette inscription</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
                <v-btn color="secondary" class="font-weight-bold d-sm-none w-100 mb-1">
                    C<span class="text-lowercase">onfirmer cette inscription</span>
                    <v-icon icon="mdi-arrow-right" class="ml-1"></v-icon>
                </v-btn>
            </router-link>

            <router-link @click="handleSubmitLead('/confirmation-recall')" to="" class="text-primary">
                Vous hésitez ? Faites vous rappeler
            </router-link>
        </div>

        <div class="mt-3">
            <v-alert
                v-if="errorMessage"
                type="error"
                class="mb-3"
                >
                {{ errorMessage }}
            </v-alert>
        </div>

        <v-overlay v-model="submitLoading" class="d-flex align-center justify-center">
            <v-progress-circular
                :size="50"
                :width="7"
                color="primary"
                indeterminate
            >
            </v-progress-circular>
        </v-overlay>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            errorMessage: '',
            submitLoading: false,
        };
    },

    computed: {
        ...mapGetters('payload', ['getParent', 'getStudents']),
    },

    methods: {
        async handleSubmitLead(route) {
            try {
                this.submitLoading = true
                const payload = {
                    email: this.getParent.email,
                    payload: this.$store.getters['payload/getPayload'],
                };

                await this.$store.dispatch('lead/submitLead', payload);
                await this.$store.dispatch('payload/clearPayload');
                await this.$store.dispatch('auth/clearApiTokenAndAuth');

                this.submitLoading = false
                this.$router.push(route);
            } catch (error) {
                console.error('Erreur lors de la soumission du lead:', error);
                this.errorMessage = "Une erreur s'est produite lors de votre inscription.";
            }
        },
    },
};
</script>

<style scoped>
@media (max-width: 576px) {
    .flex-md-row {
        flex-direction: column !important;
    }

    .justify-md-space-between {
        justify-content: flex-start !important;
    }

    .align-md-center {
        align-items: flex-start !important;
    }

    .align-items-start {
        align-items: flex-start !important;
    }
}
</style>
